import React, {FunctionComponent, useState} from 'react';
import classNames from "classnames";
import DownArrow from "../../assets/icons/ico_downarrow.svg";
import QuestionMark from "../../assets/icons/ico_question-mark.svg";

type FaqRowProps = {
    title: string,
    content: string,
    expanded?: boolean,
};

const FaqRow: FunctionComponent<FaqRowProps> = ({title, content, expanded = false}) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    const className = classNames(
        'mt-2 pr-12',
        isExpanded ? 'show' : 'hidden',
    )
    return (
        <div className="pt-6">
            <dt className="text-base">
                <button type="button"
                        className={"text-left w-full flex justify-between items-start text-ink-60 focus:outline-none"}
                        aria-controls="faq-0" aria-expanded="false"
                        onClick={() => setIsExpanded(!isExpanded)}
                >
                    <span className={classNames("flex items-center", isExpanded ? 'font-semibold text-ink-100' : 'font-medium text-ink-60')}>
                        <QuestionMark className={'w-5 h-5 mr-5'}/>
                        {title}
                    </span>
                    {/*<span className={classNames("font-medium text-ink-60", isExpanded ? 'font-semibold text-black' : '')}>*/}

                    {/*</span>*/}
                    <span className="ml-6 h-7 flex items-center">
                        <DownArrow className={classNames('transform w-5 h-5', isExpanded ? 'rotate-180' : '')}/>
                    </span>
                </button>
            </dt>
            <dd className={className} id="faq-0">
                <p className="mx-10 text-base text-ink-60">
                    {content}
                </p>
            </dd>
        </div>
    );
};

export default FaqRow;
