import React, { FunctionComponent } from 'react';
import FaqRow from "./FaqRow";

type FaqsProps = {
    items: any[],
};

const Faqs: FunctionComponent<FaqsProps> = (props) => {

    return (
        <div className="max-w-2xl divide-y-2 divide-ink-10">
            <dl className="space-y-6 divide-y divide-ink-10">
                {Array(4).fill().map(() => (
                    <FaqRow
                        title={'What type of business does Slovakia offer?'}
                        content={'I don\'t know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.'}
                    />
                ))}
            </dl>
        </div>
    );
};

export default Faqs;
