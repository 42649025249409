import React, { FunctionComponent } from 'react';
import Heading from "../components/Heading";
import Button from "../components/Button/Button";
import postImage1 from "../assets/images/postImage1.jpg";
import Section from "../components/Section";

type FeaturedPostsSectionProps = {
    posts?: any[];
};

const FeaturedPostsSection: FunctionComponent<FeaturedPostsSectionProps> = (props) => {
    const { posts } = props;

    return (
        <Section className={'py-20 bg-ink-5'}>
            <div className={'flex justify-between mb-10'}>
                <Heading type='h2'>Blog</Heading>
                <Button variant='border'>Show all</Button>
            </div>
            <div className={'grid grid-cols-3 gap-5'}>
                {Array(3).fill().map((post) => (
                    <div className={''}>
                        <img src={postImage1} alt="" className={'w-full'}/>
                        <p className={'mt-4 text-sm text-ink-60 opacity-50'}>
                            16 December 2020
                        </p>
                        <div className={'mt-2'}>
                            <Heading type='h5' className={'font-semibold'}>What is S. R. O. and what are its main benefits?</Heading>
                        </div>
                    </div>
                ))}
            </div>
        </Section>
    );
};

export default FeaturedPostsSection;
